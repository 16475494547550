import { Outlet } from "@remix-run/react";
import {
  createTheme,
  ScopedCssBaseline,
  Stack,
  ThemeProvider,
} from "@mui/material";
import theme from "../../../libs/uikit/theme";
import Container from "./components/Container";

export default function () {
  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
      })}
    >
      <ScopedCssBaseline sx={{ minHeight: "100svh", minWidth: "100svw" }}>
        <Container>
          <Stack
            justifyContent="space-between"
            sx={{ flexGrow: 1 }}
            alignItems="center"
            px={{ xs: 3, md: 11 }}
            py={11}
            spacing={3}
          >
            <Outlet />
          </Stack>
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
