import { Paper, Stack } from "@mui/material";
import React from "react";

interface IProps {
  children: React.ReactNode;
}

export default function ({ children }: IProps) {
  return (
    <>
      <Stack
        sx={{
          height: "100svh",
          width: "100svw",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Paper
          sx={(theme) => ({
            width: "100svw",
            [theme.breakpoints.up("sm")]: {
              maxWidth: "705px",
            },
          })}
        >
          {children}
        </Paper>
      </Stack>
    </>
  );
}
